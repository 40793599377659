import { Suspense } from 'react';
import { render } from 'react-dom';
import StoryChief from '@/storychief';
import lazyWithRetryPolicy from '@/storychief/utils/lazyWithRetryPolicy';

const App = lazyWithRetryPolicy(() => import('@/storychief/components/App'));

// Initialize only when the user is logged in (eg. not on the login page)
if (StoryChief.account) {
  const node = document.createElement('div');
  node.id = 'js-legacy';
  document.body.appendChild(node);

  render(
    <Suspense fallback={null}>
      <App />
    </Suspense>,
    node,
  );
}
